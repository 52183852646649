import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

type ImageProps = {
    filename: string,
    style?: object,
    className?: string
}

const Image: FC<ImageProps> = ( {
    filename,
    style = {},
    className
} ) => {
    const data = useStaticQuery( graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              sizes {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  `)

    // 全画像情報がdataに代入されている
    // render={(data) => {

    // 指定した画像ファイルパス（コンポーネントのプロパティ）と
    // 一致するgatsby-image用の情報を取得
    const image = data.images.edges.find( ( n: any ) => {
        return n.node.relativePath.includes( filename )
    } )
    !image && console.log( filename )
    if ( !image ) return null

    // Imgタグでgatsby-imageで最適化された画像を表示する
    // const imageSizes = image.node.childImageSharp.sizes
    // if(!image.node.childImageSharp){
    //   return <Img style={style}/>
    // }
    const imageSizes = image.node.childImageSharp.sizes
    // return <img src={ filename } className={ className } style={ style } />
    return <Img
        fluid={ imageSizes }
        style={ style }
        className={ className || "" }
    // fixed={ { quality: 90 } }
    // fluid={ { quality: 80 } } 
    />

}

export default Image
